import { useState } from 'react';
import moment from 'moment';
import OffboardingDeveloperModal from './OffboardingDeveloperModal';
import {
    Button,
    Modal,
    Select,
    Table,
    Tooltip,
} from 'antd';
import ConfirmAvailabilityModal from '../Pages/Developer/Components/ConfirmAvailabilityModal';
import ConfirmBillabilityModal from '../Pages/Developer/Components/ConfirmBillabilityModal';
import { updateBoarding } from '../Redux/Slices/Project/ProjectsSlice';
import {
    changeAvailability,
    changeBillability,
} from '../Redux/Slices/getUsersSlice';
import { useDispatch } from 'react-redux';
import {
    updateAvailability,
    updateBillability,
} from '../Redux/Slices/getUsersSlice';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../src/utils/LoadingSpinner';
import {
    Box,
    Typography,
    TextField,
} from '@mui/material';
import { message } from 'antd';
import { Height } from '@mui/icons-material';

const DeveloperModal = ({
    open,
    onClose,
    data,
    pms,
    id,
    fetchAgain,
    type,
    isLoading1,
    setDeveloperData,
}) => {
    const [
        isOffboardingToggle,
        setisOffboardingToggle,
    ] = useState(false);
    const [
        currentDeveloper,
        setCurrentDeveloper,
    ] = useState('');
    const [onBoardingDate, setOnBoardingDate] =
        useState('');
    const [
        selectedBoardingType,
        setSelectedBoardingType,
    ] = useState('');
    const role = localStorage.getItem(
        'designation',
    );

    const [
        confirmAvailabilityModal,
        setConfirmAvailabilityModal,
    ] = useState(false);

    const [
        confirmBillabilityModal,
        setConfirmBillabilityModal,
    ] = useState(false);

    const [userId, setUserId] = useState('');
    const [projectId, setProjectId] =
        useState('');
    const [availability, setAvailability] =
        useState('');
    const [billability, setBillability] =
        useState(0);
    const [name, setName] = useState('');
    const dayjs = require('dayjs');
    const utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
    const dispatch = useDispatch();

    const updateAvailabilityHandler = ({
        availableDate,
    }) => {
        dispatch(
            updateAvailability({
                userId: userId,
                availability: availability,
                isAvailableFrom: availableDate,
            }),
        );
    };

    const updateBillabilityHandler = () => {
        dispatch(
            updateBillability({
                userId: userId,
                projectId: projectId,
                billability: billability,
            }),
        );
    };

    const handleOffBoardingModal = () => {
        setisOffboardingToggle(false);
    };

    const columns = [
        {
            dataIndex: 'OffBoardAction',
            title: 'Action',
            width: 100,
            align: 'center',
            render: (text, record) => (
                <div>
                    <Button
                        onClick={() => {
                            setCurrentDeveloper(
                                record.objId,
                            );
                            setisOffboardingToggle(
                                true,
                            );
                            setOnBoardingDate(
                                record
                                    .OffBoardAction
                                    .onBoardingDate,
                            );
                        }}
                        style={{
                            backgroundColor:
                                ' #80808066',
                            padding: '2px 3px',
                            fontSize: '13px',
                            minWidth: '55px',
                        }}
                    >
                        Offboard
                    </Button>
                    {isOffboardingToggle && (
                        <OffboardingDeveloperModal
                            open={
                                isOffboardingToggle
                            }
                            data={data}
                            onClose={
                                handleOffBoardingModal
                            }
                            onBoardingDate={
                                onBoardingDate
                            }
                            setSelectedBoardingType={
                                setSelectedBoardingType
                            }
                            selectedBoardingType={
                                selectedBoardingType
                            }
                            currentDeveloper={
                                currentDeveloper
                            }
                            id={id}
                            fetchAgain={
                                fetchAgain
                            }
                            setDeveloperData={
                                setDeveloperData
                            }
                        />
                    )}
                </div>
            ),
        },
        {
            dataIndex: 'fullName',
            title: 'Full name',
            width: 150,
            ellipsis: 'true',
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'boardingType',
            title: 'Boarding Type',
            width: 200,
            render: (text, record) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow:
                                'ellipsis',
                        }}
                    >
                        {text !==
                        'OnObservation' ? (
                            text
                        ) : (
                            <Select
                                style={{
                                    width: 150,
                                }}
                                onChange={(
                                    value,
                                ) => {
                                    dispatch(
                                        updateBoarding(
                                            {
                                                boardingType:
                                                    value,
                                                onboardingId:
                                                    record
                                                        .OffBoardAction
                                                        ._id,
                                            },
                                        ),
                                    ).then(() =>
                                        toast.success(
                                            'Boarding updated successfully',
                                        ),
                                    );
                                }}
                                defaultValue={
                                    text
                                }
                            >
                                <Select.Option
                                    value="Onboarded"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Onboarded
                                </Select.Option>
                                <Select.Option
                                    value="OnReplaced"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    OnReplaced
                                </Select.Option>
                            </Select>
                        )}
                    </span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'isAvailable',
            title: 'Availability',
            width: 200,
            ellipsis: 'true',
            render: (text, record) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow:
                                'ellipsis',
                        }}
                    >
                        {role !== 'PM' &&
                        role !== 'CSM' &&
                        role !== 'BA' ? (
                            <Select
                                style={{
                                    width: 150,
                                }}
                                onChange={(
                                    value,
                                ) => {
                                    dispatch(
                                        changeAvailability(
                                            {
                                                userId: record
                                                    .OffBoardAction
                                                    .userId,
                                                projectId:
                                                    record
                                                        .OffBoardAction
                                                        .projectId,
                                            },
                                        ),
                                    );
                                    setUserId(
                                        record
                                            .OffBoardAction
                                            .userId,
                                    );

                                    setAvailability(
                                        value,
                                    );
                                    setConfirmAvailabilityModal(
                                        true,
                                    );
                                    setName(
                                        record.fullName,
                                    );
                                }}
                                defaultValue={
                                    text
                                }
                            >
                                <Select.Option
                                    value="Available"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Available
                                </Select.Option>
                                <Select.Option
                                    value="Enagaged"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Enagaged
                                </Select.Option>
                                <Select.Option
                                    value="mayBeAvailable"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    May Be
                                    Available
                                </Select.Option>
                                <Select.Option
                                    value="notAvailable"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Not Available
                                </Select.Option>
                                <Select.Option
                                    value="yetToBeHired"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Yet To Be
                                    Hired
                                </Select.Option>
                                <Select.Option
                                    value="offerLetterReleased"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Offer Letter
                                    Released
                                </Select.Option>
                                <Select.Option
                                    value="offerLetterAccepted"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Offer Letter
                                    Accepted
                                </Select.Option>
                                <Select.Option
                                    value="underTraining"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Under Training
                                </Select.Option>

                                <Select.Option
                                    value="backedOut"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Backed Out
                                </Select.Option>
                                <Select.Option
                                    value="Consultant"
                                    style={{
                                        marginBottom:
                                            '2px',
                                    }}
                                >
                                    Consultant
                                </Select.Option>
                            </Select>
                        ) : (
                            text
                        )}
                    </span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'lastAvailability',
            title: 'Last Availability Status',
            width: 200,
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'availabilityLogBy',
            title: 'Last Action By',
            width: 200,
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'group',
            title: 'Group',
            width: 100,
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        // {
        //     dataIndex: 'billability',
        //     title: 'Billability',
        //     width: 100,
        //     render: (text, record) => (
        //         <Tooltip
        //             title={text}
        //             placement="right"
        //             arrow
        //         >
        //             {/* <span>{text}</span> */}
        //             <Select
        //                 style={{ width: 70 }}
        //                 onChange={(value) => {
        //                     dispatch(
        //                         changeBillability(
        //                             {
        //                                 userId: record
        //                                     .OffBoardAction
        //                                     .userId,
        //                                 projectId:
        //                                     record
        //                                         .OffBoardAction
        //                                         .projectId,
        //                             },
        //                         ),
        //                     );
        //                     setUserId(
        //                         record
        //                             .OffBoardAction
        //                             .userId,
        //                     );
        //                     setProjectId(
        //                         record
        //                             .OffBoardAction
        //                             .projectId,
        //                     );
        //                     setBillability(value);
        //                     setName(
        //                         record.fullName,
        //                     );
        //                     setConfirmBillabilityModal(
        //                         true,
        //                     );
        //                 }}
        //                 defaultValue={text}
        //             >
        //                 {Array.from({
        //                     length: 101,
        //                 }).map((_, index) => {
        //                     return (
        //                         <Select.Option
        //                             value={index}
        //                             style={{
        //                                 marginBottom:
        //                                     '1px',
        //                             }}
        //                         >
        //                             {index}
        //                         </Select.Option>
        //                     );
        //                 })}
        //             </Select>
        //         </Tooltip>
        //     ),
        // },
        // {
        //     dataIndex: 'billability',
        //     title: 'Billability',
        //     width: 100,
        //     render: (text, record) => (
        //         <Tooltip
        //             title={text}
        //             placement="right"
        //             arrow
        //         >
        //             <Select
        //                 style={{ width: 70 }}
        //                 onChange={(value) => {
        //                     dispatch(
        //                         changeBillability(
        //                             {
        //                                 userId: record.OffBoardAction.userId,
        //                                 projectId: record.OffBoardAction.projectId,
        //                             },
        //                         ),
        //                     );
        //                     setUserId(record.OffBoardAction.userId);
        //                     setProjectId(record.OffBoardAction.projectId);
        //                     setBillability(value);
        //                     setName(record.fullName);
        //                     setConfirmBillabilityModal(true);
        //                 }}
        //                 defaultValue={text}
        //                 showSearch
        //                 filterOption={(input, option) =>
        //                     option?.value.toString().includes(input)
        //                 }
        //             >
        //                 {Array.from({ length: 101 }).map((_, index) => {
        //                     return (
        //                         <Select.Option
        //                             key={index}
        //                             value={index}
        //                             style={{
        //                                 marginBottom: '1px',
        //                             }}
        //                         >
        //                             {index}
        //                         </Select.Option>
        //                     );
        //                 })}
        //             </Select>
        //         </Tooltip>
        //     ),
        // },
        {
            dataIndex: 'billability',
            title: 'Billability',
            width: 100,
            render: (text, record) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <Select
                        style={{ width: 70 }}
                        onChange={(value) => {
                            dispatch(
                                changeBillability(
                                    {
                                        userId: record
                                            .OffBoardAction
                                            .userId,
                                        projectId:
                                            record
                                                .OffBoardAction
                                                .projectId,
                                    },
                                ),
                            );
                            setUserId(
                                record
                                    .OffBoardAction
                                    .userId,
                            );
                            setProjectId(
                                record
                                    .OffBoardAction
                                    .projectId,
                            );
                            setBillability(value);
                            setName(
                                record.fullName,
                            );
                            setConfirmBillabilityModal(
                                true,
                            );
                        }}
                        defaultValue={text}
                        showSearch
                        onSearch={(input) => {
                            const numericValue =
                                Number(input);
                            if (
                                isNaN(
                                    numericValue,
                                ) ||
                                numericValue > 100
                            ) {
                                message.error(
                                    'Please enter a value between 0 and 100.',
                                );
                            }
                        }}
                        filterOption={(
                            input,
                            option,
                        ) =>
                            Number(input) <=
                                100 &&
                            option?.value
                                .toString()
                                .includes(input)
                        }
                    >
                        {Array.from({
                            length: 101,
                        }).map((_, index) => (
                            <Select.Option
                                key={index}
                                value={index}
                                style={{
                                    marginBottom:
                                        '1px',
                                }}
                            >
                                {index}
                            </Select.Option>
                        ))}
                    </Select>
                </Tooltip>
            ),
        },

        {
            dataIndex: 'reportingPm',
            title:
                type === 'group'
                    ? 'Reporting manager'
                    : 'Project Manager',
            width: 150,
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'projects',
            title: 'Projects',
            width: 150,
            ellipsis: 'true',
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'experience',
            title: 'Experience',
            type: 'string',
            width: 150,
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'seniority',
            title: 'Seniority',
            width: 100,
        },
        {
            dataIndex: 'techStack',
            title: 'Tech Stack ',
            width: 150,
            ellipsis: 'true',
            render: (text) => {
                return (
                    <Tooltip
                        title={text}
                        placement="right"
                        arrow
                    >
                        <span>{text}</span>
                    </Tooltip>
                );
            },
        },
        {
            dataIndex: 'phoneNumber',
            title: 'Phone No.',
            type: 'string',
            width: 110,
        },
        {
            dataIndex: 'emergencyContactNumber',
            title: 'Emergency Number',
            type: 'string',
            width: 150,
        },
        {
            dataIndex: 'email',
            title: 'Email',
            width: 100,
            ellipsis: 'true',

            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
        {
            dataIndex: 'onBoardingDate',
            title: 'Onboarding Date',
            width: 150,
        },
        {
            dataIndex: 'remarks',
            title: 'Remarks',
            width: 300,
            ellipsis: 'true',
            align: 'center',
            render: (text) => (
                <Tooltip
                    title={text}
                    placement="right"
                    arrow
                >
                    <span>{text}</span>
                </Tooltip>
            ),
        },
    ];

    const rows =
        data &&
        data?.map((user) => {
            const response =
                user?.details?.billableProjects?.filter(
                    (project) =>
                        project.projectId === id,
                );

            if (response?.length > 0) {
                console.log(
                    response?.[0]?.billability,
                );
            }

            const joinDate = dayjs(
                user?.details?.joiningDate,
                {
                    dateFormat:
                        'YYYY-MM-DDTHH:mm:ssZ',
                },
            ).format('YYYY-MM-DDTHH:mm:ssZ');

            const onBoardingDate = dayjs
                .utc(user?.onBoardingDate)
                .format('DD/MM/YYYY');

            const currentDate = moment().format(
                'YYYY-MM-DDTHH:mm:ssZ',
            );
            const experienceAfterJoining =
                Math.floor(
                    moment(currentDate).diff(
                        joinDate,
                        'months',
                        true,
                    ),
                );
            const totalExperience =
                user?.details
                    ?.workingExperienceInMonths +
                experienceAfterJoining;
            const years = Math.floor(
                totalExperience / 12,
            );
            const months = totalExperience % 12;

            let totalExperienceString = '';

            if (years > 0) {
                totalExperienceString += `${years} Year${
                    years > 1 ? 's' : ''
                }`;
            }

            if (months > 0) {
                if (
                    totalExperienceString.length >
                    0
                ) {
                    totalExperienceString += ' ';
                }
                totalExperienceString += `${months} Month${
                    months > 1 ? 's' : ''
                }`;
            }

            if (totalExperienceString === '') {
                if (totalExperience < 1) {
                    totalExperienceString =
                        'Less than 1 Month';
                } else {
                    totalExperienceString =
                        '1 Month';
                }
            }

            return {
                OffBoardAction: user,
                empId: user?.empId,
                fullName: user?.details?.fullName,
                techStack:
                    user?.details
                        ?.primaryTechStack,
                projects:
                    user?.details?.projects
                        ?.length === 0
                        ? 'N/A'
                        : user?.details?.projects
                              ?.map(
                                  (project) =>
                                      project?.projectName,
                              )
                              .join(','),
                workingExperienceInMonths:
                    user?.details
                        ?.workingExperienceInMonths,
                experience: totalExperienceString,
                phoneNumber:
                    user?.details?.phoneNumber,
                email: user?.details?.email,
                remarks: user?.details?.remarks
                    ? user?.details?.remarks
                    : 'N/A',
                emergencyContactNumber:
                    user?.details
                        ?.emergencyContactNumber,
                designation:
                    user?.designation?.name,
                group:
                    user?.groups.length > 0
                        ? user?.groups
                              ?.map(
                                  (group) =>
                                      group.groupName,
                              )
                              .join(', ')
                        : 'N/A',
                onBoardingDate: onBoardingDate,
                seniority:
                    user?.details?.seniority,
                id: user?.email + '_' + user?._id, // for time being
                objId: user.userId
                    ? user.userId
                    : user?._id,
                isAvailable:
                    user?.details?.isAvailable ===
                    null
                        ? 'N/A'
                        : user?.details
                              ?.isAvailable,
                isDeleted:
                    user?.details?.isDeleted,
                reportingPm:
                    pms.length > 0
                        ? pms
                              ?.map(
                                  (pm) =>
                                      pm.fullName,
                              )
                              .join(', ')
                        : 'N/A',
                // billability:
                //     user?.details?.billableProjects?.filter(
                //         (project) =>
                //             project.projectId ===
                //             id,
                //     )[0].billability,
                // billability: 0,
                billability:
                    user?.details?.billableProjects?.filter(
                        (project) =>
                            project.projectId ===
                            id,
                    )?.[0]?.billability || 0,

                boardingType: user?.boardingType,
                lastAvailability: user?.details
                    ?.previousAvailability
                    ? user?.details
                          ?.previousAvailability
                    : 'NA',
                availabilityLogBy: user?.details
                    ?.availabilityChangedBy
                    ? user?.details
                          ?.availabilityChangedBy
                          ?.fullName
                    : 'NA',
            };
        });

    return (
        <>
            <Modal
                title="Onboarded Developers"
                open={open}
                onCancel={onClose}
                width={800}
                bodyStyle={
                    isLoading1
                        ? { maxHeight: '55vh' }
                        : {}
                }
                footer={false}
            >
                <div>
                    {isLoading1 ? (
                        <LoadingSpinner />
                    ) : (
                        <Table
                            dataSource={rows}
                            columns={columns}
                            pagination={{
                                showQuickJumper: true,
                                showSizeChanger: true,
                            }}
                            rowKey="id"
                            size="middle"
                            scroll={{
                                x: 1000,
                                y: 300,
                            }}
                        />
                    )}
                </div>
            </Modal>
            {confirmAvailabilityModal && (
                <ConfirmAvailabilityModal
                    open={
                        confirmAvailabilityModal
                    }
                    onClose={() =>
                        setConfirmAvailabilityModal(
                            false,
                        )
                    }
                    updateAvailabilityHandler={
                        updateAvailabilityHandler
                    }
                    name={name}
                    availability={availability}
                />
            )}
            {confirmBillabilityModal && (
                <ConfirmBillabilityModal
                    open={confirmBillabilityModal}
                    onClose={() =>
                        setConfirmBillabilityModal(
                            false,
                        )
                    }
                    updateBillabilityHandler={
                        updateBillabilityHandler
                    }
                    name={name}
                    billability={billability}
                />
            )}
        </>
    );
};

export default DeveloperModal;
