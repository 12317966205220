import {
    Card,
    CardContent,
    Grid,
    Tooltip,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, {
    useEffect,
    useState,
} from 'react';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import CodeSharpIcon from '@mui/icons-material/CodeSharp';
import {
    getAllProjectManagers,
    getDevelopers,
    getProjects,
    getTechStacks,
} from '../../Redux/Slices/Lists/ListsSlice';
import { getTotalDevelopers } from '../../Redux/Slices/Lists/ListsSlice';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import '../../../src/Assets/CSS/Dashboard/dashboard.css';
import {
    getDeveloperBarChart,
    getDeveloperPieChart,
    getProjectPieChart,
    getProjectPmChart,
    getDelayedProjectData,
    getbarChartData,
} from '../../Redux/Slices/Dashboard/DashboardSlice';
import PieChart from './component/PieChart';
import { Select } from 'antd';
import BarChart from './component/BarChart';
import DoughnutChart from './component/DoughnutChart';
import StackedBarChart from './component/StackedBarChart';
// import { MenuItem } from "@mui/base";
import { useNavigate } from 'react-router-dom';
import { setFilterParameters } from '../../Redux/Slices/getUsersSlice';
import { setProjectsData } from '../../Redux/Slices/Project/ProjectsSlice';

const DashboardItem = () => {
    const query =
        '/api/v2/users/search?isDeleted=false';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        projects,
        currentPage,
        dataPerPage,
        totalDevelopers,
        techStacks,
        projectManagers,
    } = useSelector((store) => store.lists);

    const { searchFilters } = useSelector(
        (store) => store?.getUsers,
    );

    const {
        pieDeveloperData,
        barData,
        pieProjectData,
        pmWiseProjectsData,
        delayedProjectData,
        barChartData,
    } = useSelector((store) => store.dashboard);
    const [
        selectedDeveloperCategory,
        setSelectedDeveloperCategory,
    ] = useState('Availability');
    const [
        selectedProjectCategory,
        setSelectedProjectCategory,
    ] = useState('Group');
    const [
        selectedDeveloperBarCategory,
        setSelectedDeveloperBarCategory,
    ] = useState('Techstack');
    const [
        selectedProjectStatusCategory,
        setSelectedProjectStatusCategory,
    ] = useState('Delayed');

    // const [selectedTechStacks, setSelectedTechStacks] = useState("");
    // const [selectedProjectManager, setSelectedProjectManager] = useState("");
    const [
        selectedCategory,
        setSelectedCategory,
    ] = useState('onboarding developers');

    let pieDeveloperLabels = pieDeveloperData.map(
        (pie) => pie.name,
    );
    let pieDeveloperCount = pieDeveloperData.map(
        (e) => {
            return e.count;
        },
    );
    let pieProjectLabels = pieProjectData.map(
        (pie) => pie.name,
    );
    let pieProjectCount = pieProjectData.map(
        (e) => {
            return e.count;
        },
    );
    let barLabels = barData
        .filter((bar) => bar.totalCount !== 0)
        .map((bar) => bar.name);
    console.log(barData);
    let barCount = barData.map(
        (bar) => bar.count,
    );
    let barAvailable = barData.map(
        (bar) => bar.availableCount,
    );
    let barEngagedCount = barData.map(
        (bar) => bar.engagedCount,
    );

    let pmProjectsBarLabels = pmWiseProjectsData
        .filter((bar) => bar.count !== 0)
        .map((bar) => bar.name);

    let pmProjectsBarCount = pmWiseProjectsData
        .map((bar) => bar.count)
        .filter((bar) => bar !== 0);
    console.log(pmProjectsBarCount);

    useEffect(() => {
        dispatch(getProjects());
        dispatch(
            getDeveloperPieChart('Availability'),
        );
        dispatch(
            getDeveloperBarChart('Techstack'),
        );
        dispatch(getProjectPieChart('Group'));
        dispatch(getProjectPmChart());
        dispatch(
            getDelayedProjectData(
                selectedProjectStatusCategory,
            ),
        );
        dispatch(getTechStacks());
        dispatch(
            getAllProjectManagers({ role: 'PM' }),
        );
        dispatch(
            getbarChartData('getOnboardingData'),
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(getTotalDevelopers());
        dispatch(getDevelopers(query));
    }, [currentPage, dataPerPage, dispatch]);

    const handleSelectDeveloperCategory = (
        value,
    ) => {
        dispatch(getDeveloperPieChart(value));
    };

    const handleSelectProjectCategory = (
        value,
    ) => {
        dispatch(getProjectPieChart(value));
    };

    const handleSelectDeveloperBarCategory = (
        value,
    ) => {
        dispatch(getDeveloperBarChart(value));
    };

    // const handleSelectDelayedProjectCategory = (value) => {
    //   dispatch(getDelayedProjectData(value));
    // };

    // const handleSelectTechStacks = (value) => {
    //   // console.log("check", value);
    // };

    // const handleSelectedProjectManager = (value) => {
    //   // console.log("check", value);
    // };

    // console.log("projectManager", projectManagers);

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
        if (value === 'onboarding developers') {
            dispatch(
                getbarChartData(
                    'getOnboardingData',
                ),
            );
        } else if (
            value === 'offboarding developers'
        ) {
            dispatch(
                getbarChartData(
                    'getOffboardingData',
                ),
            );
        } else {
            dispatch(
                getbarChartData(
                    'getprojectsData',
                ),
            );
        }
    };

    const barLabelOnboardings = [
        'Last 7 days',
        'Last 15 days',
        'Last 30 days',
    ];
    const barLabelTotalCount = [
        barChartData?.last7DaysData?.length,
        barChartData?.last15DaysData?.length,
        barChartData?.last30DaysData?.length,
    ];

    const handleBarClick = (count) => {
        count = count.split(' ')[1];

        let key = selectedCategory.includes(
            'onboarding developers',
        )
            ? 'onboardedDevs'
            : selectedCategory.includes(
                  'offboarding developers',
              )
            ? 'offboardedDevs'
            : selectedCategory.includes(
                  'projects',
              )
            ? 'latestProjects'
            : '';

        if (
            selectedCategory ===
            'onboarding developers'
        ) {
            dispatch(
                setFilterParameters({
                    ...searchFilters,
                    onboardedDevs: count,
                    offboardedDevs: '',
                }),
            );
            navigate('/dashboard/developer');
        } else if (
            selectedCategory ===
            'offboarding developers'
        ) {
            dispatch(
                setFilterParameters({
                    ...searchFilters,
                    offboardedDevs: count,
                    onboardedDevs: '',
                }),
            );
            navigate('/dashboard/developer');
        } else {
            dispatch(setProjectsData(count));
            navigate('/dashboard/projects');
        }
        console.log(
            'label',
            selectedCategory,
            key,
            count,
        );
    };

    return (
        <Box className="pl-[24px] pr-[24px]">
            <Grid
                container
                rowGap={3}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={8}
                    >
                        <Card
                            sx={{
                                boxShadow: 4,
                            }}
                        >
                            <CardContent
                                sx={{
                                    'display':
                                        'flex',

                                    'flexDirection':
                                        'row',

                                    'alignItems':
                                        'center',

                                    '&:last-child':
                                        {
                                            padding:
                                                '21px  16px!important',
                                        },
                                }}
                            >
                                <Box
                                    component="div"
                                    sx={{
                                        width: '50%',
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        component="div"
                                    >
                                        Developers
                                    </Typography>

                                    <Box
                                        component="div"
                                        sx={{
                                            padding:
                                                '5px 0px',

                                            display:
                                                'flex',

                                            justifyContent:
                                                'space-between',

                                            alignItems:
                                                'center',
                                        }}
                                    >
                                        <CodeSharpIcon
                                            sx={{
                                                backgroundColor:
                                                    'white',

                                                padding:
                                                    '4px',

                                                fontSize:
                                                    '2rem',

                                                borderRadius:
                                                    '2rem',
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Box
                                    component="div"
                                    sx={{
                                        width: '50%',
                                    }}
                                >
                                    <Box
                                        component="div"
                                        sx={{
                                            display:
                                                'flex',

                                            alignItems:
                                                'center',

                                            justifyContent:
                                                'space-between',
                                        }}
                                    >
                                        <Tooltip
                                            title="Total Developers"
                                            placement="right"
                                            arrow
                                        >
                                            <Typography
                                                sx={{
                                                    width: '80%',

                                                    textOverflow:
                                                        'ellipsis',

                                                    whiteSpace:
                                                        'nowrap',

                                                    overflow:
                                                        'hidden',
                                                }}
                                            >
                                                Total
                                                Developers
                                            </Typography>
                                        </Tooltip>

                                        <Typography
                                            sx={{
                                                fontSize:
                                                    '12px',
                                            }}
                                        >
                                            {
                                                totalDevelopers.totalCount
                                            }
                                        </Typography>
                                    </Box>

                                    <Box
                                        component="div"
                                        sx={{
                                            display:
                                                'flex',

                                            alignItems:
                                                'center',

                                            justifyContent:
                                                'space-between',

                                            color: 'green',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '80%',
                                            }}
                                        >
                                            Engaged
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize:
                                                    '12px',
                                            }}
                                        >
                                            {
                                                totalDevelopers.engagedCount
                                            }
                                        </Typography>
                                    </Box>

                                    <Box
                                        component="div"
                                        sx={{
                                            display:
                                                'flex',

                                            alignItems:
                                                'center',

                                            justifyContent:
                                                'space-between',

                                            color: 'red',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '80%',
                                            }}
                                        >
                                            Available
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize:
                                                    '12px',
                                            }}
                                        >
                                            {
                                                totalDevelopers.availableCount
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Card
                            sx={{
                                boxShadow: 4,
                            }}
                        >
                            <CardContent
                                sx={{
                                    '&:last-child':
                                        {
                                            paddingBottom:
                                                '16px !important',
                                        },
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    component="div"
                                >
                                    Projects
                                </Typography>

                                <Box
                                    component="div"
                                    sx={{
                                        padding:
                                            '5px 0px',

                                        display:
                                            'flex',

                                        justifyContent:
                                            'space-between',

                                        alignItems:
                                            'center',
                                    }}
                                >
                                    <WorkSharpIcon
                                        sx={{
                                            backgroundColor:
                                                'white',

                                            padding:
                                                '4px',

                                            fontSize:
                                                '2rem',

                                            borderRadius:
                                                '2rem',

                                            color: 'black',
                                        }}
                                    />

                                    <Box component="div">
                                        <Typography>
                                            Total
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize:
                                                    '12px',

                                                display:
                                                    'flex',

                                                justifyContent:
                                                    'flex-end',
                                            }}
                                        >
                                            {
                                                projects?.length
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <div className="flex gap-3">
                <div className="flex-col justify-center mt-[1rem] w-[40%] h-[75vh] bg-white rounded p-3 shadow-md">
                    <div className="mb-[2rem] flex justify-between">
                        <Select
                            value={
                                selectedDeveloperCategory
                            }
                            onChange={(value) => {
                                setSelectedDeveloperCategory(
                                    value,
                                );
                                handleSelectDeveloperCategory(
                                    value,
                                );
                            }}
                            size="large"
                            style={{
                                width: '10rem',
                            }}
                        >
                            <Select.Option value="Availability">
                                <p>
                                    Availability
                                </p>
                            </Select.Option>
                            <Select.Option value="Location">
                                <p>Location</p>
                            </Select.Option>
                            <Select.Option value="Seniority">
                                <p>Seniority</p>
                            </Select.Option>
                        </Select>
                        <p className="font-extrabold">
                            Developers
                        </p>
                    </div>
                    <div>
                        <PieChart
                            labels={
                                pieDeveloperLabels
                            }
                            count={[
                                ...pieDeveloperCount,
                            ]}
                        />
                    </div>
                </div>
                <div className="w-[64%] h-[75vh] bg-white rounded p-3 mt-[1rem] shadow-md">
                    <div className="mb-[2rem] flex justify-between">
                        <div className="flex gap-[1rem]">
                            <Select
                                value={
                                    selectedDeveloperBarCategory
                                }
                                onChange={(
                                    value,
                                ) => {
                                    setSelectedDeveloperBarCategory(
                                        value,
                                    );
                                    handleSelectDeveloperBarCategory(
                                        value,
                                    );
                                }}
                                size="large"
                                style={{
                                    width: '10rem',
                                }}
                            >
                                <Select.Option value="Techstack">
                                    <p>
                                        Techstack
                                    </p>
                                </Select.Option>
                                <Select.Option value="Designation">
                                    <p>
                                        Designation
                                    </p>
                                </Select.Option>
                            </Select>
                            {/* <Select
                onChange={(value) => {
                  setSelectedTechStacks(value);
                  handleSelectTechStacks(value);
                }}
                size="large"
                style={{
                  width: "12rem",
                  maxWidth: "12rem",
                  maxHeight: "3rem",
                }}
                mode="multiple"
                showSearch={true}
                optionFilterProp="children"
                maxTagCount={1} // Set maxTagCount to control the number of displayed tags
                placeholder="Select Techstack"
              >
                {techStacks?.map((techStack) => (
                  <MenuItem key={techStack?._id} value={techStack?._id}>
                    {techStack?.name}
                  </MenuItem>
                ))}
              </Select> */}
                        </div>
                        <p className="font-extrabold">
                            Developers
                        </p>
                    </div>
                    <div>
                        {selectedDeveloperBarCategory ===
                        'Techstack' ? (
                            <StackedBarChart
                                barEngagedCount={
                                    barEngagedCount
                                }
                                barAvailable={
                                    barAvailable
                                }
                                barLabels={
                                    barLabels
                                }
                            />
                        ) : (
                            <BarChart
                                barCount={
                                    barCount
                                }
                                barLabels={
                                    barLabels
                                }
                                labelName="Number of Developers"
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex gap-3">
                <div className="flex-col justify-center mt-[1rem] mb-[1rem] w-[40%] h-[75vh] bg-white rounded p-3 shadow-md">
                    <div className="mb-[2rem] flex justify-between">
                        <Select
                            value={
                                selectedProjectCategory
                            }
                            onChange={(value) => {
                                setSelectedProjectCategory(
                                    value,
                                );
                                handleSelectProjectCategory(
                                    value,
                                );
                            }}
                            size="large"
                            style={{
                                width: '10rem',
                            }}
                        >
                            <Select.Option value="Status">
                                <p>Status</p>
                            </Select.Option>
                            <Select.Option value="Group">
                                <p>Group</p>
                            </Select.Option>
                            <Select.Option value="TypeOfProject">
                                <p>
                                    Type of
                                    project
                                </p>
                            </Select.Option>
                        </Select>
                        <p className="font-extrabold">
                            Projects
                        </p>
                    </div>
                    <div className="w-full h-[auto]">
                        <DoughnutChart
                            labels={
                                pieProjectLabels
                            }
                            count={[
                                ...pieProjectCount,
                            ]}
                        />
                    </div>
                </div>
                <div className="w-[64%] h-[75vh] bg-white rounded p-3 mt-[1rem] shadow-md">
                    <div className="mb-[2rem] flex justify-end">
                        {/* <div className="flex gap-[1rem]">
              <Select
                onChange={(value) => {
                  setSelectedProjectManager(value);
                  handleSelectedProjectManager(value);
                }}
                size="large"
                style={{
                  width: "16rem",
                  maxHeight: "3rem",
                }}
                mode="multiple"
                showSearch={true}
                optionFilterProp="children"
                maxTagCount={1} // Set maxTagCount to control the number of displayed tags
                placeholder="Select Project Manager"
              >
                {projectManagers?.map((projectManager) => (
                  <MenuItem
                    key={projectManager?._id}
                    value={projectManager?._id}
                  >
                    {projectManager?.fullName}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
                        <p className="font-extrabold">
                            Projects
                        </p>
                    </div>
                    <div className="mb-[2rem]">
                        <BarChart
                            barLabels={
                                pmProjectsBarLabels
                            }
                            barCount={
                                pmProjectsBarCount
                            }
                            labelName="Number of Projects"
                        />
                    </div>
                </div>
            </div>
            <div className=" bg-white h-[75vh] mb-[1rem] rounded p-3 shadow-md">
                <div className="mb-[1rem]">
                    <Select
                        defaultValue={
                            'onboarding developers'
                        }
                        onChange={(value) => {
                            handleCategoryChange(
                                value,
                            );
                        }}
                        size="large"
                        style={{ width: '14rem' }}
                    >
                        <Select.Option value="onboarding developers">
                            <p>
                                Onboarding
                                Developers
                            </p>
                        </Select.Option>
                        <Select.Option value="offboarding developers">
                            <p>
                                Offboarding
                                Developers
                            </p>
                        </Select.Option>
                        <Select.Option value="projects">
                            <p>Projects</p>
                        </Select.Option>
                    </Select>

                    <BarChart
                        barCount={
                            barLabelTotalCount
                        }
                        barLabels={
                            barLabelOnboardings
                        }
                        labelName={
                            selectedCategory
                        }
                        onBarClick={
                            handleBarClick
                        }
                        barThickness={70}
                    />
                </div>
            </div>
        </Box>
    );
};

export default DashboardItem;
